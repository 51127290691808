import styled from 'styled-components'

export const StyledHero = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100vh - 96px);
`

const StyledParagraph = styled.p`
  color: #FFFFFF;
  font-size: 50px;
  line-height: 72px;

  @media screen and (min-width: 375px) {
    font-size: 60px;
  }

  @media screen and (min-width: 768px) {
    font-size: 80px;
    line-height: 110px;
  }

  @media screen and (min-width: 1024px) {
    font-size: 104px;
    line-height: 124px;
  }

  @media screen and (min-width: 1280px) {
    font-size: 124px;
    line-height: 164px;
  }

  @media screen and (min-width: 1440px) {
    font-size: 144px;
    line-height: 184px;
  }

  @media screen and (min-width: 1920px) {
    font-size: 200px;
    line-height: 220px;
  }
`

export const StyledFirstLine = styled(StyledParagraph)`
  font-weight: 500;
`

export const StyledSecondLine = styled(StyledParagraph)`
  font-weight: 100;
`

export const StyledCoinImage = styled.img`
  width: 42px;
  animation: coinAnimate 2s ease-in-out infinite;

  @media screen and (min-width: 1024px) {
    width: 90px;
  }

  @keyframes coinAnimate {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(15px);
    }

    100% {
      transform: translateY(0);
    }
  }
`

export const StyledPersonImage = styled.img`
  width: 100%;
  margin: 32px 0;

  @media screen and (min-width: 768px) {
    width: 130px;
    margin: 0;
  }

  @media screen and (min-width: 1024px) {
    width: 202px;
  }

  @media screen and (min-width: 1920px) {
    width: 270px;
  }
`

export const StyledPersonTwoImage = styled.img`
  @media screen and (min-width: 768px) {
    width: 60px;
  }

  @media screen and (min-width: 1280px) {
    width: 100px;
  }

  @media screen and (min-width: 1920px) {
    width: 135px;
  }
`

export const StyledHashtag = styled.span`
  color: #202020;
  font-size: 24px;
  display: flex;
  align-items: flex-start;

  @media screen and (min-width: 375px) {
    font-size: 32px;
  }

  @media screen and (min-width: 768px) {
    font-size: 70px;
  }

  @media screen and (min-width: 1024px) {
    font-size: 106px;
    line-height: 124px;
  }

  @media screen and (min-width: 1280px) {
    font-size: 124px;
    line-height: 164px;
  }

  @media screen and (min-width: 1440px) {
    font-size: 144px;
    line-height: 184px;
  }

  @media screen and (min-width: 1920px) {
    font-size: 200px;
    line-height: 220px;
  }
`

export const StyledArrow = styled.img`
  margin-left: 24px;
  animation: arrowDown 1s ease-in-out infinite;

  @media screen and (min-width: 1024px) {
    margin-left: 0;
    margin-top: 40px;
  }

  @media screen and (min-width: 1280px) {
    margin-left: 0;
    margin-top: 90px;
  }

  @media screen and (min-width: 1920px) {
    margin-top: 120px;
  }

  @keyframes arrowDown {
    from {
      transform: translateY(0);
    }

    to {
      transform: translateY(10px);
    }
  }
`
