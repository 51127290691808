import React from 'react'

import { GlobalStyle } from '../styles/pix-gratuito'

import Header from '../components/pix-gratuito/Header'
import Footer from '../components/pix-gratuito/Footer'
import SEO from '../components/seo'

import PIXHero from '../partials/PIX/Hero'
import JoinSection from '../partials/PIX/JoinSection'
import StatsSection from '../partials/PIX/StatsSection'

const PixPage = () => {
  return (
    <>
      <SEO
        title="Contador PIX PJ da Cora"
        description="Tarifa do PIX PJ? Na Cora Não! Veja o quanto os clientes da Cora já economizaram em tarifas usando o PIX na Cora."
        linkCanonical="pix-gratuito"
      />

      <GlobalStyle />

      <Header />

      <PIXHero />

      <StatsSection />

      <JoinSection />

      <Footer />
    </>
  )
}

export default PixPage
