import React from 'react'

import {
  StyledFooter,
  StyledFooterLinks,
  StyledFooterSocialContainer,
  StyledLinkSocial,
  StyledHashtag,
  StyledCopyright,
  StyledTextAdditional,
} from './styled'

const Footer = () => (
  <StyledFooter>
    <StyledFooterLinks>
      <StyledFooterSocialContainer>
        <StyledLinkSocial>
          <a
            className="facebook"
            href="https://www.facebook.com/cora.bancopj/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Facebook
            <span>Facebook</span>
            <span>Facebook</span>
          </a>
        </StyledLinkSocial>

        <StyledLinkSocial>
          <a
            className="instagram"
            href="https://www.instagram.com/cora.bancopj/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Instagram
            <span>Instagram</span>
            <span>Instagram</span>
          </a>
        </StyledLinkSocial>

        <StyledLinkSocial>
          <a
            className="linkedin"
            href="https://www.linkedin.com/company/cora-bancopj/"
            target="_blank"
            rel="noopener noreferrer"
          >
            LinkedIn
            <span>LinkedIn</span>
            <span>LinkedIn</span>
          </a>
        </StyledLinkSocial>

        <StyledLinkSocial>
          <a className="cora" href="/">
            Cora
            <span>Cora</span>
            <span>Cora</span>
          </a>
        </StyledLinkSocial>
      </StyledFooterSocialContainer>

      <StyledHashtag>
        <div className="animate-hashtag">
          <p className="normal">
            <span className="thin">#</span>coradescomplica
          </p>
          <p className="normal">
            <span className="thin">#</span>coradescomplica
          </p>
        </div>
      </StyledHashtag>
    </StyledFooterLinks>

    <StyledCopyright>
      <span className="simbol">®</span> {new Date().getFullYear()} - CORA
    </StyledCopyright>

    <StyledTextAdditional>
      *valor obtido por meio do volume de PIX enviado por nossos clientes e a
      taxa média praticada pelo mercado
    </StyledTextAdditional>
  </StyledFooter>
)

export default Footer
