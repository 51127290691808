import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive'

import * as Styles from './stats-section.module.css'

import CoinMobile from './coin-mobile'
import CoinDesktop from './coin-desktop'
import StatsPIX from '../../../components/pix-gratuito/Stats'

const Mobile = ({ children }: { children: React.ReactNode }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })

  return isMobile ? children : null
}

const Default = ({ children }: { children: React.ReactNode }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 })

  return isNotMobile ? children : null
}

const StatsSection = () => {
  return (
    <section className={`container ${Styles.stats}`}>
      <p className={Styles.stats__text}>
        Olha só o quanto os clientes da <span className="font-700">Cora</span> estão economizando<span className="font-900">:</span>
      </p>

      <Mobile>
        <CoinMobile />
      </Mobile>

      <Default>
        <CoinDesktop />
      </Default>

      <StatsPIX />
    </section>
  )
}

export default StatsSection
