import React, { useState, useEffect } from 'react'
import CountUp from 'react-countup'
import VisibilitySensor from 'react-visibility-sensor'

import * as Styles from './stats.module.css'

import StarIcon from '../../../images/pix-gratuito/star.svg'

const StatsPIX = () => {
  const [QuantityPIX, setQuantityPIX] = useState<number>(0)
  const [didViewCountUp, setDidViewCountUp] = useState<boolean>(false)

  useEffect(() => {
    const URL = 'https://spreadsheets.google.com/feeds/cells/1e5JByI0FpMdGckKz5fnLvr0BOPZ_hVpNV3ujctSUiso/1/public/full?alt=json'

    fetch(URL)
      .then((response) => response.json())
      .then((data) => setQuantityPIX(data.feed.entry[1].content.$t))
  }, [])

  const getAmountTaxesPIX = (): number => {
    return QuantityPIX * 11
  }

  const formatAmount = (value: number): string => {
    const formattedAmount = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value)

    return formattedAmount.replace('R$', '').trim()
  }

  const getValue = (value: string): number => {
    const splitValue = value.split('.')

    return parseInt(splitValue[0], 10)
  }

  const getStringValue = (value: string): string => {
    const splitValue = value.split('.')
    const values = {
      singular: ['real', 'mil', 'milhão', 'bilhão', 'trilhão', 'quadrilhão'],
      plural: ['reais', 'mil', 'milhões', 'bilhões', 'trilhões', 'quadrilhões']
    }

    if (parseInt(splitValue[0]) > 1) {
      return values.plural[splitValue.length - 1]
    }

    return values.singular[splitValue.length - 1]
  }

  return (
    <VisibilitySensor
      onChange={(isVisible) => { if (isVisible) setDidViewCountUp(true)}}
      partialVisibility
      delayedCall
    >
      <section className={Styles.stats}>
        <div className={Styles.stats__number}>
          <img className={Styles.stats__star} src={StarIcon} alt="Ícone de uma estrela com várias pontas"/>

          <CountUp end={didViewCountUp ? getValue(formatAmount(getAmountTaxesPIX())) : 0} duration={5} redraw={true} />
        </div>

        <div className={Styles.stats__infos}>
          <p className={Styles.stats__text}>{getStringValue(formatAmount(getAmountTaxesPIX()))}</p>

          <span className={Styles.stats__amount}>
            <span className="currency-symbol">R$</span>
            <CountUp end={didViewCountUp ? getAmountTaxesPIX() : 0} redraw={true} duration={5} formattingFn={formatAmount} />*
          </span>
        </div>
      </section>
    </VisibilitySensor>
  )
}

export default StatsPIX
