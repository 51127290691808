import React from 'react'

import LogoCora from '../../../../src/images/pix-gratuito/logo-cora.svg'

import { StyledHeader, StyledLogo, StyledButtonHeader } from './styled'

const Header = () => (
  <div className="container">
    <StyledHeader>
      <StyledLogo src={LogoCora} alt="Logo da Cora"/>
      <StyledButtonHeader href='https://conta.cora.com.br/p/f/pix/v3/?utm_source=pixometro&utm_medium=seja-cora-cta&utm_campaign=cora-descomplica' target="_blank">Seja Cora</StyledButtonHeader>
    </StyledHeader>
  </div>
)

export default Header
