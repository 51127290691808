import styled from 'styled-components'

export const StyledHeader = styled.header`
  padding: 24px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const StyledLogo = styled.img`
  width: 32px;
  height: 32px;

  @media screen and (min-width: 768px) {
    width: 48px;
    height: 48px;
  }

  @media screen and (min-width: 1920px) {
    width: 48px;
    height: 48px;
  }
`

export const StyledButtonHeader = styled.a`
  display: flex;
  align-items: center;
  font-family: 'Inter', sans-serif;
  background-color: #202020;
  border-radius: 600px;
  padding: 12px 25px;
  width: 130px;
  height: 48px;
  color: #FFFFFF;
  text-decoration: none;
  cursor: pointer;
  box-shadow: 0px 24px 32px rgba(32, 32, 32, 0.2);
  justify-content: center;
  font-weight: 700;
  line-height: 24px;

  @media screen and (min-width: 768px) {
    box-shadow: none;
    width: 183px;
  }
`
