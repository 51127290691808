import React from 'react'
import { useMediaQuery } from 'react-responsive'

import CoinMobileImage from '../../../images/pix-gratuito/coin-mobile.png'
import CoinDesktopImage from '../../../images/pix-gratuito/coin-desktop.png'
import PersonOneMobileImage from '../../../images/pix-gratuito/person1-mobile.jpg'
import PersonOneDesktopImage from '../../../images/pix-gratuito/person1-desktop.jpg'
import PersonTwoImage from '../../../images/pix-gratuito/person2-desktop.jpg'
import ArrowImage from '../../../images/pix-gratuito/arrow.svg'
import ArrowDesktopImage from '../../../images/pix-gratuito/arrow-desktop.svg'

import { StyledHero, StyledFirstLine, StyledCoinImage, StyledSecondLine, StyledPersonImage, StyledPersonTwoImage, StyledHashtag, StyledArrow } from './styled'

const Mobile = ({ children }: { children: React.ReactNode }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })

  return isMobile ? children : null
}

const Default = ({ children }: { children: React.ReactNode }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 })

  return isNotMobile ? children : null
}

const PIXHero = () => (
  <StyledHero className="container">
    <Mobile>
      <StyledFirstLine>
        Tarifa <StyledCoinImage src={CoinMobileImage} alt="Imagem de uma moeda de 1 real"/> do
      </StyledFirstLine>
      <StyledSecondLine>
        <span className="font-900">PIX</span> <span className="font-400">PJ</span>? na
      </StyledSecondLine>
      <StyledSecondLine>
        Cora não!
      </StyledSecondLine>

      <StyledPersonImage src={PersonOneMobileImage} alt="Imagem de uma pessoa negra sorrindo"/>

      <StyledHashtag>
        #coradescomplica <StyledArrow src={ArrowImage} alt="imagem de uma seta apontado para baixo" />
      </StyledHashtag>
    </Mobile>

    <Default>
      <StyledFirstLine>
        Tarifa <StyledCoinImage src={CoinDesktopImage} alt="Imagem de uma moeda de 1 real"/> do <span className="font-900">PIX</span> <span className="font-400">PJ</span><span className="font-100">?</span>
      </StyledFirstLine>
      <StyledSecondLine>
        na <StyledPersonTwoImage src={PersonTwoImage} alt="Imagem de uma pessoa olhando para frente"/> Cora não! <StyledPersonImage src={PersonOneDesktopImage} alt="Imagem de uma pessoa sorrindo"/>
      </StyledSecondLine>

      <StyledHashtag>
        #coradescomplica <StyledArrow src={ArrowDesktopImage} alt="imagem de uma seta apontado para baixo" />
      </StyledHashtag>
    </Default>
  </StyledHero>
)

export default PIXHero
