import styled, { createGlobalStyle } from 'styled-components'
import reset from 'styled-reset'

export const GlobalStyle = createGlobalStyle`
  ${reset}

  *, *::after, *::before {
    box-sizing: border-box;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
  }

  body {
    background-color: #FE3E6D;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 16px;
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100vw;
    -webkit-overflow-scrolling: touch;
  }

  strong {
    font-weight: 900;
  }

  .container {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 1920px;
    padding: 0 24px;
    width: 100%;

    @media screen and (min-width: 768px) {
      padding: 0 32px;
    }

    @media screen and (min-width: 1280px) {
      padding: 0 72px;
    }
  }

  .font-100 {
    font-weight: 100;
  }

  .font-200 {
    font-weight: 200;
  }

  .font-400 {
    font-weight: 400;
  }

  .font-500 {
    font-weight: 500;
  }

  .font-700 {
    font-weight: 700;
  }

  .font-900 {
    font-weight: 900;
  }
`
