import styled from 'styled-components'

export const StyledJoinSection = styled.section`
  height: 100vh;
  flex-direction: column;
  justify-content: center;
`

export const StyledParagraph = styled.p`
  color: #FFF;
  font-size: 54px;
  line-height: 72px;
  font-weight: 200;

  @media screen and (min-width: 1024px) {
    font-size: 104px;
    line-height: 124px;
  }

  @media screen and (min-width: 1280px) {
    font-size: 124px;
    line-height: 144px;
  }

  @media screen and (min-width: 1440px) {
    font-size: 144px;
    line-height: 184px;
  }

  @media screen and (min-width: 1920px) {
    font-size: 200px;
    line-height: 220px;
  }
`

export const StyledThirdLine = styled(StyledParagraph)`
  display: flex;
  align-items: center;
`

export const StyledImage = styled.img`
  width: 88px;

  @media screen and (min-width: 1920px) {
    width: 117px;
  }
`

export const StyledSecondImage = styled.img`
  width: 151px;

  @media screen and (min-width: 1920px) {
    width: 201px;
  }
`

export const StyledButton = styled.a`
  display: flex;
  align-items: center;
  font-size: 16px;
  background-color: #202020;
  border-radius: 600px;
  padding: 12px 25px;
  width: 100%;
  height: 48px;
  color: #FFFFFF;
  text-decoration: none;
  cursor: pointer;
  box-shadow: 0px 24px 32px rgba(32, 32, 32, 0.2);
  justify-content: center;
  font-weight: 700;
  line-height: 24px;
  margin-top: 60px;

  @media screen and (min-width: 768px) {
    box-shadow: none;
    width: 227px;
    font-size: 22px;
    line-height: 32px;
    height: 64px;
    margin: 0 54px;
  }
`
