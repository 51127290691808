import React from 'react'

import * as Styles from './coin-desktop.module.css'

import Coin1 from '../../../images/pix-gratuito/coin-desktop-1.png'
import Coin2 from '../../../images/pix-gratuito/coin-desktop-2.png'
import Coin3 from '../../../images/pix-gratuito/coin-desktop-3.png'
import Coin4 from '../../../images/pix-gratuito/coin-desktop-4.png'
import Coin5 from '../../../images/pix-gratuito/coin-desktop-5.png'
import Coin6 from '../../../images/pix-gratuito/coin-desktop-6.png'

const CoinDesktop = () => (
  <div className={Styles.stats__coins}>
    <img src={Coin1} alt="imagem de uma moeda de 1 real" className={Styles.coin1}/>
    <img src={Coin2} alt="imagem de uma moeda de 1 real" className={Styles.coin2}/>
    <img src={Coin3} alt="imagem de uma moeda de 1 real" className={Styles.coin3}/>
    <img src={Coin4} alt="imagem de uma moeda de 1 real" className={Styles.coin4}/>
    <img src={Coin5} alt="imagem de uma moeda de 1 real" className={Styles.coin5}/>
    <img src={Coin6} alt="imagem de uma moeda de 1 real" className={Styles.coin6}/>
  </div>
)

export default CoinDesktop
