import styled from 'styled-components'

export const StyledFooter = styled.footer`
  padding-bottom: 74px;
`

export const StyledFooterLinks = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 1280px) {
    flex-direction: row;
  }
`

export const StyledFooterSocialContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media screen and (min-width: 1280px) {
    width: 50%;
  }
`

export const StyledLinkSocial = styled.div`
  padding: 17px 47px;
  border-color: #FFF;
  border-style: solid;
  width: 50%;
  overflow: hidden;
  transition: all .3s ease-in-out;

  &:first-child {
    border-width: 2px 2px 2px 0;
  }

  &:nth-child(2) {
    border-width: 2px 0 2px 0;
  }

  &:nth-child(3) {
    border-width: 0 2px 2px 0;
  }

  &:last-child {
    border-width: 0 0 2px 0;
  }

  a {
    display: block;
    font-size: 16px;
    line-height: 24px;
    position: relative;
    width: 100%;
    font-weight: 500;
    color: #FFF;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
  }

  span {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }

  &:hover {
    background-color: #FFF;

    a {
      color: #FE5B83;
    }

    span {
      color: #FE5B83;

      &:first-child {
        animation: moveRightToLeft .3s .2s ease-in-out forwards;
      }

      &:last-child {
        animation: moveLeftToRight .3s .2s ease-in-out forwards;
      }
    }

    .cora {
      span {
        &:first-child {
          animation: coraRightToLeft .3s .2s ease-in-out forwards;
        }

        &:last-child {
          animation: coraLeftToRight .3s .2s ease-in-out forwards;
        }
      }
    }
  }

  @keyframes moveRightToLeft {
    from {
      transform: translateX(0);
    }

    to {
      transform: translateX(-60%);
    }
  }

  @keyframes moveLeftToRight {
    from {
      transform: translateX(0);
    }

    to {
      transform: translateX(60%);
    }
  }

  @keyframes coraRightToLeft {
    from {
      transform: translateX(0);
    }

    to {
      transform: translateX(-50%);
    }
  }

  @keyframes coraLeftToRight {
    from {
      transform: translateX(0);
    }

    to {
      transform: translateX(50%);
    }
  }
`

export const StyledHashtag = styled.div`
  display: flex;
  border-bottom: 2px solid #FFF;
  padding: 20px 0;
  overflow: hidden;
  position: relative;
  height: 120px;

  @media screen and (min-width: 1280px) {
    width: 50%;
    border-top: 2px solid #FFF;
    border-left: 2px solid #FFF;
  }

  .thin {
    font-weight: 100;
  }

  .normal {
    font-weight: 500;
  }

  .animate-hashtag {
    display: block;
    width: 200%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    animation: animateHashtag 25s infinite linear;
  }

  .animate-hashtag p {
    font-size: 64px;
    color: #FFF;
    float: left;
    width: 50%;
  }

  .animate-hashtag p:nth-child(2) {
    display: none;

    @media screen and (min-width: 768px) {
      display: block
    }
  }

  .animate-hashtag:hover {
    animation-play-state: paused;
  }

  @keyframes animateHashtag {
    0% { left: 0; }
    100% { left: -100%; }
  }
`

export const StyledCopyright = styled.div`
  display: flex;
  color: #FFF;
  font-size: 12px;
  line-height: 16px;
  align-items: center;
  font-weight: 400;
  padding: 56px 24px 0;

  @media screen and (min-width: 1280px) {
    font-size: 24px;
  }

  .simbol {
    font-weight: 100;
    font-size: 40px;
    line-height: 16px;
    margin-right: 8px;
    margin-top: 6px;

    @media screen and (min-width: 1280px) {
      font-size: 50px;
    }
  }
`

export const StyledTextAdditional = styled.p`
  font-weight: 200;
  font-size: 16px;
  line-height: 24px;
  color: #202020;
  margin-top: 32px;
  padding: 0 24px;
`
