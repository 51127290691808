import React from 'react'
import { useMediaQuery } from 'react-responsive'

import FirstImage from '../../../images/pix-gratuito/join-image.jpg'
import SecondImage from '../../../images/pix-gratuito/join-image-2.jpg'

import { StyledJoinSection, StyledParagraph, StyledThirdLine, StyledImage, StyledSecondImage, StyledButton } from './styled'

const Mobile = ({ children }: { children: React.ReactNode }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })

  return isMobile ? children : null
}

const Default = ({ children }: { children: React.ReactNode }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 })

  return isNotMobile ? children : null
}

const JoinSection = () => (
  <StyledJoinSection className="container">
    <Mobile>
      <StyledParagraph>Junte<span className="font-900">-</span>se a eles e vem ser Cora você também!</StyledParagraph>

      <StyledButton href="/">Conheça a Cora</StyledButton>
    </Mobile>

    <Default>
      <StyledParagraph>
        Junte<span className="font-900">-</span>se a eles <StyledImage src={FirstImage} alt="imagem de uma mulher dançando" /> e
      </StyledParagraph>
      <StyledParagraph>
        vem ser Cora você
      </StyledParagraph>
      <StyledThirdLine>
        <span className="font-700">também!</span> <StyledButton href="https://www.cora.com.br/blog/cora-descomplica/" target="_blank">Conheça a Cora</StyledButton> <StyledSecondImage src={SecondImage} alt="imagem de uma mulher sorrindo com os braços abertos"/>
      </StyledThirdLine>
    </Default>
  </StyledJoinSection>
)

export default JoinSection
